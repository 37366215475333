<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">考官管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ lastPageData.adminId ? '编辑' : '新增' }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="ruleForm" class="ruleForm" :rules="rules" :model="ruleForm" label-width="100px" size="small"
              style="width: 500px;">
              <el-form-item label="姓名" prop="officerName">
                <el-input type="text" v-model="ruleForm.officerName" clearable placeholder="请输入姓名" />
              </el-form-item>
              <el-form-item label="联系方式" prop="mobile">
                <el-input v-model="ruleForm.mobile" placeholder="请输入联系方式"></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="idCard">
                <el-input v-model="ruleForm.idCard" placeholder="请输入身份证号"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="bgc-bv" @click="$router.back()">取 消</el-button>
                <el-button class="bgc-bv" @click="sureData">确 定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "systemManage_supervise_addAndedit",
  data() {
    return {
      // 上个页面的数据
      lastPageData: {
        officerId: "", // 考官id
      },
      // 基本信息
      ruleForm: {
        officerName: "", // 姓名
        idCard: "", // 身份证号
        mobile: "", // 联系方式
      },
      // 基本信息 - 数据校验
      rules: {
        fullname: [
          { required: true, trigger: "blur", message: "请输入姓名" }
        ],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" }
        ],
        idCard: [
          { required: true, validator: this.$validateIdCard, trigger: 'blur' }
        ],
      },
    };
  },
  created() {
    this.lastPageData.officerId = this.$route.query?.officerId ?? "";
    if (this.lastPageData.officerId) {
      this.getDetail();
    }
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 详情
    getDetail() {
      this.$post("/biz/officer/info", {
        officerId: this.lastPageData.officerId
      }, 3000, true, 10).then((res) => {
        this.ruleForm = { ...this.ruleForm, ...res.data }
      });
    },
    // 保存 - 新增&编辑
    sureData() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let cData = {
            ...this.ruleForm,
          }
          let url = '';
          if (this.lastPageData.officerId) {
            url = '/biz/officer/modify'
          } else {
            url = '/biz/officer/insert'
          }
          this.$post(url, cData, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功')
              this.$router.back();
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
  }
};
</script>
<style lang="less" scoped>
.ruleForm {
  .fromTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
</style>